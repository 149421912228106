import * as actionTypes from './actionTypes';

import { defaultLanguage } from 'constants/onboarding/defaultOnboardingData';

import ApiClient from 'services/ApiClient';

import { ActionType } from 'types/commonInterfaces';

import { UserStore } from 'interfaces/Store/userStore';

const initialState: UserStore = {
    name: '',
    surname: '',
    email: null,
    is_login: false,
    user_id: null,
    isLoading: false,
    token: null,
    payment_type: null,
    language: defaultLanguage,
    country: localStorage.getItem('country') || null,
    isAutologinComplete: false,
    paidStatus: false,
    signInErrorMessage: '',
    showForgotPasswordHint: false,
    subscriptionInfo: [],
    shouldUserMoveToPage: false,
    defaultAmazonAnalyticData: null,
    purchaseProductList: {
        appAccess: false,
        vipSupport: false,
        guidesList: [],
    },
    purchasedProducts: [],
    fullURL: '',
    isInitTestania: true,
    userErrorInfo: null,
    gender: null,
    userGuidesList: null,
    branchForChallenge: null,
};

const UserReducer = (state = initialState, action: ActionType<keyof UserStore>) => {
    switch (action.type) {
        case actionTypes.SIGN_UP:
            return { ...state, isLoading: true };

        case actionTypes.SIGN_IN:
            return { ...state, signInErrorMessage: null, isLoading: true };

        case actionTypes.SIGN_UP_SUCCESS:
            return { ...state, isLoading: false };

        case actionTypes.SIGN_UP_FAIL:
            return { ...state, isLoading: false };

        case actionTypes.SET_EMAIL:
            return { ...state, email: action.payload };

        case actionTypes.SET_USER_NAME:
            return { ...state, name: action.payload };

        case actionTypes.SET_USER_SURNAME:
            return { ...state, surname: action.payload };

        case actionTypes.SET_IS_LOGIN:
            return { ...state, is_login: action.payload };

        case actionTypes.SET_USER_ID:
            return { ...state, user_id: action.payload };

        case actionTypes.SET_PAYMENT_TYPE:
            return { ...state, payment_type: action.payload };

        case actionTypes.SET_USER_TOKEN:
            ApiClient.utils.cookies.setToken(action.payload);

            return { ...state, token: action.payload };

        case actionTypes.UPDATE_INFO_OLD:
            return { ...state, isLoading: true };

        case actionTypes.UPDATE_INFO_FAIL:
            return { ...state, isLoading: false };

        case actionTypes.UPDATE_INFO_SUCCESS:
            return { ...state, isLoading: false };

        case actionTypes.SET_LANGUAGE:
            return { ...state, language: action.payload };

        case actionTypes.SET_COUNTRY:
            localStorage.setItem('country', action.payload);

            return { ...state, country: action.payload };

        case actionTypes.SET_IS_AUTOLOGIN_COMPLETE:
            return { ...state, isAutologinComplete: action.payload };

        case actionTypes.SET_PAID_STATUS:
            return { ...state, paidStatus: action.payload };

        case actionTypes.SHOULD_USER_MOVE_TO_PAGE:
            return { ...state, shouldUserMoveToPage: action.payload };

        case actionTypes.SET_DEFAULT_AMAZON_ANALYTIC_DATA:
            return { ...state, defaultAmazonAnalyticData: action.payload };

        case actionTypes.SET_PURCHASED_PRODUCT_LIST:
            return { ...state, purchaseProductList: action.payload };

        case actionTypes.SET_FULL_URL:
            return { ...state, fullURL: action.payload };

        case actionTypes.SET_IS_INIT_TESTANIA:
            return { ...state, isInitTestania: action.payload };

        case actionTypes.SET_USER_ERROR_INFO:
            return { ...state, userErrorInfo: action.payload };

        case actionTypes.SET_GENDER:
            return { ...state, gender: action.payload };

        case actionTypes.SET_BRANCH_FOR_CHALLENGE:
            return { ...state, branchForChallenge: action.payload };

        default:
            return state;
    }
};

export default UserReducer;
