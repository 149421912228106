import { InitPaymentGenerator } from 'solid-payment/src/interfaces/payment';
import Solid from 'solid-payment';
import { SENTRY_PAYMENT } from 'sentry-utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { InitConfig } from '@solidgate/client-sdk-loader';

import { handleErrorAction } from 'redux/User/actions';
import { selectCurrentProduct } from 'redux/Payment/selectors';
import * as actionTypes from 'redux/Payment/actionTypes';
import { setIsLoadingBankCard, setMerchantData } from 'redux/Payment/actions';
import { ModalName } from 'redux/Modals/types';
import { openModal } from 'redux/Modals/actions';

import { DOUBLE_PURCHASE_ERROR } from 'constants/errors/errors';

import { CurrentProduct } from 'types/payments/payments';
import { ActionType } from 'types/commonInterfaces';

import { init as initSolid } from './initSolid';

export function* init({ payload }: ActionType<InitPaymentGenerator>) {
    try {
        yield put(setIsLoadingBankCard(true));
        yield call(initSolid);

        const response: InitConfig['merchantData'] = yield Solid.init('paymentGenerator', payload);

        yield put(setMerchantData(response));
    } catch (error) {
        if (error?.response.data.message === DOUBLE_PURCHASE_ERROR) {
            yield put(setIsLoadingBankCard(false));

            const currentProduct: CurrentProduct = yield select(selectCurrentProduct);

            yield put(
                openModal(ModalName.doublePurchasedAlert, {
                    productTitle: 'Muscle Booster',
                    payment_type: currentProduct.payment_type,
                })
            );
        }
        yield put(setIsLoadingBankCard(false));
        yield put(handleErrorAction(error, SENTRY_PAYMENT, payload));
    }
}

export const initPaymentGenerator = function* () {
    yield takeLatest(actionTypes.INIT_PAYMENT_GENERATOR, init);
};
