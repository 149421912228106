export const VALIDATE_PAYMENT = 'VALIDATE_PAYMENT';

export const INIT_PAYMENT = 'INIT_PAYMENT';
export const INIT_SOLID_PAYMENT = 'INIT_SOLID_PAYMENT';
export const INIT_PAY_PAL = 'INIT_PAY_PAL';
export const INIT_PAYMENT_GENERATOR = 'INIT_PAYMENT_GENERATOR';
export const SET_MERCHANT_DATA = 'SET_MERCHANT_DATA';
export const VALIDATE_PAYMENT_DATA = 'VALIDATE_PAYMENT_DATA';
export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA';
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';
export const SET_UPGRADED_PRODUCT = 'SET_UPGRADED_PRODUCT';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_PAYMENT_CURRENCY = 'SET_PAYMENT_CURRENCY';
export const INIT_ONE_CLICK_PAYMENT = 'INIT_ONE_CLICK_PAYMENT';
export const SET_UPSELL_PRODUCT_ID = 'SET_UPSELL_PRODUCT_ID';
export const SET_UPSELL_PRODUCT = 'SET_UPSELL_PRODUCT';
export const CHANGE_PAYMENT_TYPE = 'CHANGE_PAYMENT_TYPE';
export const SET_PURCHASE_WITHOUT_VALUE = 'SET_PURCHASE_WITHOUT_VALUE';
export const SET_INTRODUCTORY_DISCOUNT = 'SET_INTRODUCTORY_DISCOUNT';
export const SET_REPLACE_SUBSCRIPTION = 'SET_REPLACE_SUBSCRIPTION';
export const SET_SUBSCRIPTION_ID = 'SET_SUBSCRIPTION_ID';
export const SET_SANDBOX = 'SET_SANDBOX';
export const SET_CURRENT_CANCEL_SUBSCRIPTION_ID = 'SET_CURRENT_CANCEL_SUBSCRIPTION_ID';
export const SET_OPEN_MODAL = 'SET_OPEN_MODAL';
export const CLOSE_MODALS = 'CLOSE_MODALS';
export const SET_EXCLUSIVE_OFFER_HAS_BEEN_OPENED = 'SET_EXCLUSIVE_OFFER_HAS_BEEN_OPENED';
export const SET_EXCLUSIVE_OFFER_MODAL_STATUS_CLOSE = 'SET_EXCLUSIVE_OFFER_MODAL_STATUS_CLOSE';
export const SET_IS_PAYMENT_SCREEN = 'SET_IS_PAYMENT_SCREEN';
export const SET_CURRENT_PRODUCT_CODE = 'SET_CURRENT_PRODUCT_CODE';
export const SET_IS_TIMER_END = 'SET_IS_TIMER_END';

export const SET_CUSTOM_PAYMENT_BUTTON = 'SET_CUSTOM_PAYMENT_BUTTON';
export const SET_HIDE_CUSTOM_PAYMENT_BUTTON = 'SET_HIDE_CUSTOM_PAYMENT_BUTTON';
export const SET_IS_APPLE_PAY_AVAILABLE = 'SET_IS_APPLE_PAY_AVAILABLE';
export const SET_PAYMENT_RETENO_ERROR = 'SET_PAYMENT_RETENO_ERROR';
export const SET_PAYMENT_RETENO_ERROR_STORE = 'SET_PAYMENT_RETENO_ERROR_STORE';
export const SET_IS_LOADING_BANK_CARD = 'SET_IS_LOADING_BANK_CARD';
export const SET_IS_LOADING_PAY_PAL = 'SET_IS_LOADING_PAY_PAL';
export const SET_IS_PAYMENT_SUCCESS = 'SET_IS_PAYMENT_SUCCESS';
export const SET_USER_INVOICE_INFO_RECEIVED = 'SET_USER_INVOICE_INFO_RECEIVED';

export const SET_IS_GOOGLE_PAY_AVAILABLE = 'SET_IS_GOOGLE_PAY_AVAILABLE';
