/* eslint-disable max-lines */
import { configType } from 'interfaces/Testania/testania';

import * as actionTypes from './actionTypes';

import { TrainingDaysGoal } from 'types/pages/onboardingPages/commonTypes';
import {
    TargetZonesType,
    WorkoutLocationsType,
    MeasuringSystemType,
    SedentaryLifeStyleType,
    ActivityLvlType,
    OccasionList,
    FitnessLevelType,
    CurrentBodyType,
    TargetBodyType,
    SkipOnboarding,
    IAgeGroup,
    EquipmentPresets,
    TrainingDuration,
} from 'types/onboarding/onboarding';

import { ValueWithMeasuringSystem } from 'interfaces/Onboarding/onboarding';

export function setOnboardingConfig(config: configType) {
    return {
        type: actionTypes.SET_CONFIG,
        payload: config,
    } as const;
}

export function setGoal(goal: string) {
    return {
        type: actionTypes.SET_GOAL,
        payload: goal,
    } as const;
}

export function setGoalWide(goalWide: string) {
    return {
        type: actionTypes.SET_GOAL_WIDE,
        payload: goalWide,
    } as const;
}

export function setWorkoutLocation(locations: Array<WorkoutLocationsType>) {
    return {
        type: actionTypes.SET_TRAINING_LOCATION,
        payload: locations,
    } as const;
}

export function setTargetZone(targetZones: Array<TargetZonesType>) {
    return {
        type: actionTypes.SET_TARGET_ZONE,
        payload: targetZones,
    } as const;
}

export function setCurrentBody(bodyType: CurrentBodyType) {
    return {
        type: actionTypes.SET_CURRENT_BODY,
        payload: bodyType,
    } as const;
}

export function setCurrentBodyTypeA(bodyType: CurrentBodyType) {
    return {
        type: actionTypes.SET_CURRENT_BODY_TYPE_A,
        payload: bodyType,
    } as const;
}

export function setCurrentWeight(currentWeightWithUnit: ValueWithMeasuringSystem) {
    return {
        type: actionTypes.SET_CURRENT_WEIGHT,
        payload: currentWeightWithUnit,
    } as const;
}

export function setTargetBody(bodyType: TargetBodyType) {
    return {
        type: actionTypes.SET_TARGET_BODY,
        payload: bodyType,
    } as const;
}

export function setTargetWeight(weightWithUnit: ValueWithMeasuringSystem) {
    return {
        type: actionTypes.SET_TARGET_WEIGHT,
        payload: weightWithUnit,
    } as const;
}

export function setFitnessLvl(fitnessLevel: FitnessLevelType) {
    return {
        type: actionTypes.SET_FITNESS_LVL,
        payload: fitnessLevel,
    } as const;
}

export function setAge(age: number) {
    return {
        type: actionTypes.SET_AGE,
        payload: age,
    } as const;
}

export function setTall(tall: ValueWithMeasuringSystem) {
    return {
        type: actionTypes.SET_TALL,
        payload: tall,
    } as const;
}

export function setPulseMailingActive(isActive: boolean) {
    return {
        type: actionTypes.SET_PULSE_MAILING_ACTIVE,
        payload: isActive,
    } as const;
}

export function setMeasure(system: MeasuringSystemType | string) {
    return {
        type: actionTypes.SET_MEASURE_SYSTEM,
        payload: system,
    } as const;
}

export function setNextStep(pageName: string) {
    return {
        type: actionTypes.SET_NEXT_STEP,
        payload: pageName,
    } as const;
}

export function initAutoLogin() {
    return {
        type: actionTypes.INIT_AUTO_LOGIN,
    } as const;
}

export function setURLParams(url: Record<string, string> | null) {
    return {
        type: actionTypes.SET_URL_PARAMS,
        payload: url,
    } as const;
}

export function skipOnboarding(payload: SkipOnboarding) {
    return {
        type: actionTypes.SKIP_ONBOARDING,
        payload: payload,
    } as const;
}

export function setLifeStyle(payload: SedentaryLifeStyleType) {
    return {
        type: actionTypes.SET_LIFESTYLE,
        payload: payload,
    } as const;
}

export function setActivityLvl(payload: ActivityLvlType) {
    return {
        type: actionTypes.SET_ACTIVITY_LVL,
        payload: payload,
    } as const;
}

export function setOccasion(occasionList: OccasionList) {
    return {
        type: actionTypes.SET_OCCASION,
        payload: occasionList,
    } as const;
}

export function setAgeGroup(ageGroup: IAgeGroup) {
    return {
        type: actionTypes.SET_AGE_GROUP,
        payload: ageGroup,
    } as const;
}

export function setSport(sport: string) {
    return {
        type: actionTypes.SET_SPORT,
        payload: sport,
    } as const;
}

export function setIsSkipOB(isSkipOB: boolean) {
    return {
        type: actionTypes.SET_IS_SKIP_OB,
        payload: isSkipOB,
    } as const;
}

export function setEquipmentPreset(equipment: EquipmentPresets) {
    return {
        type: actionTypes.SET_EQUIPMENT_PRESET,
        payload: equipment,
    } as const;
}

export function setTrainingDaysGoal(days: TrainingDaysGoal) {
    return {
        type: actionTypes.SET_TRAINING_DAYS_GOAL,
        payload: days,
    } as const;
}

export function setTrainingDuration(time: TrainingDuration) {
    return {
        type: actionTypes.SET_TRAINING_DURATION,
        payload: time,
    } as const;
}

export function setScoreRateEndurance(score: number) {
    return {
        type: actionTypes.SET_SCORE_RATE_ENDURANCE,
        payload: score,
    } as const;
}

export function setScoreRateConsistency(score: number) {
    return {
        type: actionTypes.SET_SCORE_RATE_CONSISTENCY,
        payload: score,
    } as const;
}

export function setScoreRateStrength(score: number) {
    return {
        type: actionTypes.SET_SCORE_RATE_STRENGTH,
        payload: score,
    } as const;
}
