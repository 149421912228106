import { useTranslation } from 'react-i18next';

import config from '../config';

import { CheckoutConfigType } from 'interfaces/Payments/payments';

const useCheckoutConfig = (checkoutConfig?: CheckoutConfigType) => {
    const { t } = useTranslation();

    const mainTitlesKeys = {
        checkout: t('paymentFlow.checkoutGolf.title'),
        select: t('paymentFlow.checkoutFoxtrot.title'),
        results: t('Get visible results in 4 weeks'),
    };

    const secondTitleKeys = {
        payment: t('paymentFlow.checkoutFoxtrot.title'),
        results: t('Get visible results in 4 weeks'),
    };

    const defaultConfig = {
        mainTitle: mainTitlesKeys.checkout,
        secondTitle: secondTitleKeys.payment,
        paymentMethod: {
            payPall: true, // TODO: FIX TYPO IN JSON VALIDATION SCHEMA OF CONFIG
            googlePay: false, // The default value for all checkouts if the checkout page config is not exists
        },
    };

    const mainTitle = checkoutConfig?.mainTitle || defaultConfig.mainTitle;
    const secondTitle = checkoutConfig?.secondTitle || defaultConfig.secondTitle;
    const paymentMethod = checkoutConfig?.paymentMethod || defaultConfig.paymentMethod;

    const displayPaypalByConfig = config?.HIDE_PAYPAL !== '1';

    return {
        mainTitle: mainTitlesKeys[mainTitle as keyof typeof mainTitlesKeys],
        secondTitle: secondTitleKeys[secondTitle as keyof typeof secondTitleKeys],
        paymentMethod: {
            payPal: displayPaypalByConfig && paymentMethod.payPall, // TODO: FIX TYPO IN JSON VALIDATION SCHEMA OF CONFIG
            googlePay: paymentMethod.googlePay,
        },
    };
};

export default useCheckoutConfig;
