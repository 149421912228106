import { call, delay, put, putResolve, select, takeLatest } from 'redux-saga/effects';

import { setLanguage, setUserEmail, signUp } from 'redux/User/actions';
import { selectTestaniaName } from 'redux/Testania/selectors';
import { setCompleteOBs, setTestaniaLoginStatus } from 'redux/Testania/actions';
import { init as InitSolid } from 'redux/sagas/payment/initSolid';
import { SKIP_ONBOARDING } from 'redux/Onboadring/actionTypes';
import { setIsSkipOB, skipOnboarding } from 'redux/Onboadring/actions';

import { getLocalizationFromAvailable } from 'helpers/other/commonUtils';
import { generateRandomIntBetween } from 'helpers/onboarding/onboardingUtils';

function* skip({ payload }: ReturnType<typeof skipOnboarding>) {
    const generatedEmail = `mbw3+skipob${generateRandomIntBetween(100_000, 999_999)}@amazingapps.tech`;

    try {
        const language = getLocalizationFromAvailable();
        const completeObs: string = yield select(selectTestaniaName);

        yield put(setIsSkipOB(true));
        yield put(setUserEmail(generatedEmail));

        yield putResolve(
            // @ts-ignore
            signUp()
        );

        yield put(setLanguage(language));
        yield put(setCompleteOBs(completeObs));
        yield put(setTestaniaLoginStatus(true));
        yield call(InitSolid);
        yield delay(3000);

        yield call(alert, generatedEmail);
        yield call(payload.reload);
    } catch (error) {
        console.warn(`Skip onboarding warn ${error}`);
    }
}

export const skipOnBoardingSaga = function* () {
    yield takeLatest(SKIP_ONBOARDING, skip);
};
