import { systemNames } from 'wikr-core-analytics';

import { BRANCH_NAMES, TEST_NAMES } from 'hooks/Testania/constants';
import { defaultBranchName, defaultTestName } from 'constants/onboarding/defaultOnboardingData';

import { excludeAnalyticsSystems } from 'helpers/analytic/excludeAnalyticsSystems';

import { CurrentProduct } from 'types/payments/payments';

import { isOneMonthProduct } from './isOneMonthProduct';

export type GetPurchaseOptionsType = (
    { period, trial }: Partial<CurrentProduct>,
    urlParams: Record<string, string>
) => { analytics: string[] };

export const getPurchaseOptions: GetPurchaseOptionsType = ({ period, trial }, urlParams) => {
    const branchName = urlParams?.['branch-name'] || defaultBranchName;
    const testName = urlParams?.['test-name'] || defaultTestName;

    if (!isOneMonthProduct({ period, trial })) {
        return { analytics: [] };
    }

    if (branchName === BRANCH_NAMES.general && testName === TEST_NAMES.go12kal) {
        return {
            analytics: excludeAnalyticsSystems([systemNames.pixel]),
        };
    }

    if (branchName === BRANCH_NAMES.google && testName === TEST_NAMES.go11til) {
        return {
            analytics: excludeAnalyticsSystems([systemNames.ga4, systemNames.ga]),
        };
    }

    return { analytics: [] };
};
