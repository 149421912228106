import ReactModal from 'react-modal';
import { Trans } from 'react-i18next';
import React, { memo, PropsWithChildren, useEffect } from 'react';
import classNames from 'classnames/bind';

import { useNetworkStatus } from 'hooks';

import { Text } from 'libComponents';
import Timer from 'components/Timer';
import ModalClose from 'components/ModalClose/ModalClose';

import modalStyles from './Modal.module.css';

import ClockImg from 'assets/img/icons/miniClock.svg';

import { IModalProps } from 'types/pages/onboardingPages/commonTypes';

const cx = classNames.bind(modalStyles);

function Modal({
    children,
    isOpen = false,
    onClose,
    withClose = true,
    withTransparency,
    withLeftClose,
    withFixedWidth,
    withFlexbox,
    withExitPopup,
    withOpacity,
    isShowModal,
    isCheckout,
    withTimer,
    timerTime,
    timerEndHandler,
    isTimerEnd,
    withoutTopMargin,
    withCustomModalContainer,
    withExclusiveOffer,
    withUpsellSubscription,
    centeredModal,
}: PropsWithChildren<IModalProps>) {
    useEffect(() => {
        ReactModal.setAppElement('#app');
    }, []);

    const isOnline = useNetworkStatus();

    const closeModal = () => {
        if (onClose) {
            onClose();
        }
    };

    const contentStyles = cx({
        modalContainer__withOnTimer: withTimer && !isTimerEnd,
        modalContainer__withOffTimer: withTimer && isTimerEnd,
        modalContainer: !withCustomModalContainer,
        desktopStyles: !withCustomModalContainer,
        withCustomModalContainer,
        withTransparency,
        withExclusiveOffer,
        withFlexbox,
        withFixedWidth,
        withExitPopup,
        withUpsellSubscription,
        centeredModal,
    });

    const overlayStyles = cx({
        defaultOverlay: !withCustomModalContainer && isOnline,
        customOverlay: withCustomModalContainer && isOnline,
        overlayWithOpen: withOpacity && !isShowModal,
        overlayWithClose: withOpacity && isShowModal,
        overlayWithTransparency: withTransparency,
        networkModalOverlay: centeredModal,
    });

    const timerClass = cx('timer', {
        timerEnd: isTimerEnd,
    });

    return (
        <ReactModal
            shouldCloseOnOverlayClick
            shouldCloseOnEsc={false}
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            ariaHideApp={false}
            className={contentStyles}
            overlayClassName={overlayStyles}
        >
            {withTimer && timerTime && (
                <div className={timerClass}>
                    <span>
                        <ClockImg />
                    </span>
                    <Text type="caption" bold center>
                        <Trans i18nKey="paymentFlow.upsellSub.timer.text" />
                        &nbsp;
                        <Timer initTimerTime={timerTime} endTime={0} timerEndCallback={timerEndHandler} />
                        &nbsp;
                        <Trans i18nKey="min" />
                    </Text>
                </div>
            )}
            {onClose && withClose && (
                <ModalClose
                    onClick={closeModal}
                    withLeftClose={withLeftClose}
                    withRightClose={withTimer}
                    withMarginTop={!isTimerEnd && !withTransparency && !withoutTopMargin}
                    isCheckout={isCheckout}
                />
            )}
            {children}
        </ReactModal>
    );
}

export default memo(Modal);
