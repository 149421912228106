import { ERROR_LEVELS, SENTRY_TESTANIA } from 'sentry-utils';

import api from 'api';

import { setPaidStatus, setUserName, setUserSurname } from 'redux/User/actions';
import { clearCompleteOBs, setCompleteOBs, setCurrentBranchName, setTestaniaLoginStatus } from 'redux/Testania/actions';
import { setMeasure } from 'redux/Onboadring/actions';

import { COUNTRIES_IMPERIAL_SYSTEM, COUNTRIES_LIST } from 'constants/general/countriesData';
import { URL_PARAMS_FOR_SKIP_THANK_YOU_PAGE } from './constants';

import { sentryCaptureEvent } from 'modules/ErrorHandler/ErrorBoundary';

export const getBranchNameFromLocalStorage = () => {
    const config = localStorage.getItem('config');

    return JSON.parse(config)?.branch_name;
};

export const getNameFromUrl = (name, defaultValue) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(name) || defaultValue;
};

export const setCurrentBranchToStore = async (branch, dispatch) => {
    dispatch(setCurrentBranchName(branch));
};

export const setCompleteOBsToStore = async (branch, dispatch) => {
    dispatch(setCompleteOBs(branch));
};

export const setTestaniaLoginStatusToStore = async (status, dispatch) => {
    dispatch(setTestaniaLoginStatus(status));
};

export const getIsLogin = async (testaniaLoginStatus, token) => {
    return testaniaLoginStatus === 'true' || !!token || false;
};

const fetchUserStatus = async () => {
    try {
        const { data } = await api.user.getUser();
        const { is_paid: isPaid = false, is_password: isPasswordSet = false, name, surname } = data;

        return { isPaid, isPasswordSet, name, surname };
    } catch (e) {
        sentryCaptureEvent(e, SENTRY_TESTANIA, ERROR_LEVELS.ERROR);

        return { isPaid: false, isPasswordSet: false };
    }
};

export const getUserStatus = async (dispatch, token) => {
    const userStatus = { isPaid: false, isPasswordSet: false, name: '', surname: '' };

    if (token) {
        const { isPaid, isPasswordSet, name, surname } = await fetchUserStatus();

        userStatus.isPaid = isPaid;
        userStatus.isPasswordSet = isPasswordSet;
        userStatus.name = name;
        userStatus.surname = surname;
    }

    dispatch(setPaidStatus(userStatus.isPaid));
    dispatch(setUserName(userStatus.name));
    dispatch(setUserSurname(userStatus.surname));

    return userStatus;
};

export const clearCompleteOBsToStore = async (dispatch) => {
    dispatch(clearCompleteOBs());
};

export const setInitMeasure = (country, dispatch) => {
    dispatch(setMeasure(COUNTRIES_IMPERIAL_SYSTEM.includes(country) ? 'IMPERIAL' : 'METRIC'));
};

export const validateCountry = (country) => {
    const countryCodeList = COUNTRIES_LIST.map((el) => el.code);

    return Boolean(typeof country === 'string' && countryCodeList.includes(country));
};

export const shouldSkipThankYouPage = (testaniaName, urlParams) => {
    const isTest = testaniaName?.split('__').pop() === 'TestB';
    const branchName = urlParams?.['branch-name'];
    const testName = urlParams?.['test-name'];

    return Boolean(
        isTest &&
            branchName === URL_PARAMS_FOR_SKIP_THANK_YOU_PAGE.branchName &&
            testName === URL_PARAMS_FOR_SKIP_THANK_YOU_PAGE.testName
    );
};
