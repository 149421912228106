import { createSelector } from 'reselect';

import { RootState } from 'redux/rootReducer';

const selectOnBoarding = (state: RootState) => state.onboarding;
const selectGoal = createSelector(selectOnBoarding, (onboarding) => onboarding.goal);
const selectGoalWide = createSelector(selectOnBoarding, (onboarding) => onboarding.goal_wide);
const selectTargetWeight = createSelector(selectOnBoarding, (onboarding) => onboarding.targetWeight);
const selectCurrentWeight = createSelector(selectOnBoarding, (onboarding) => onboarding.currentWeight);
const selectTargetZone = createSelector(selectOnBoarding, (onboarding) => onboarding.target_zone);
const selectFitnessLevel = createSelector(selectOnBoarding, (onboarding) => onboarding.fitness_lvl);
const selectTall = createSelector(selectOnBoarding, (onboarding) => onboarding.tall);
const selectAge = createSelector(selectOnBoarding, (onboarding) => onboarding.age);
const selectTrainingLocations = createSelector(selectOnBoarding, (onboarding) => onboarding.training_locations);
const selectMeasureSystem = createSelector(selectOnBoarding, (onboarding) => onboarding.measureSystem);
const selectCurrentBody = createSelector(selectOnBoarding, (onboarding) => onboarding.current_body);
const selectCurrentBodyTypeA = createSelector(selectOnBoarding, (onboarding) => onboarding.current_body_type_a);
const selectTargetBody = createSelector(selectOnBoarding, (onboarding) => onboarding.target_body);
const selectConsentMarketingStatus = createSelector(selectOnBoarding, (onboarding) => onboarding.consent_marketing);
const selectPreferActivity = createSelector(selectOnBoarding, (onboarding) => onboarding.preferActivity);
const selectNextStep = createSelector(selectOnBoarding, (onboarding) => onboarding.nextStep);
const selectConfig = createSelector(selectOnBoarding, (onboarding) => onboarding.config);
const selectNumberSystem = createSelector(selectOnBoarding, (onboarding) => onboarding.measureSystem);
const selectURLParams = createSelector(selectOnBoarding, (onboarding) => onboarding.urlParams);
const selectSedentaryLifeStyle = createSelector(selectOnBoarding, (onboarding) => onboarding.sedentaryLifeStyle);
const selectActivityLvl = createSelector(selectOnBoarding, (onboarding) => onboarding.activityLvl);
const selectOccasion = createSelector(selectOnBoarding, (onboarding) => onboarding.occasion);
const selectAgeGroup = createSelector(selectOnBoarding, (onboarding) => onboarding.ageGroup);
const selectSport = createSelector(selectOnBoarding, (onboarding) => onboarding.sport);
const selectIsSkipOB = createSelector(selectOnBoarding, (onboarding) => onboarding.isSkipOB);
const selectEquipmentPreset = createSelector(selectOnBoarding, (onboarding) => onboarding.equipment_preset);
const selectTrainingDaysGoal = createSelector(selectOnBoarding, (onboarding) => onboarding.training_days_goal);
const selectTrainingDuration = createSelector(selectOnBoarding, (onboarding) => onboarding.training_duration_goal);
const selectScoreRateEndurance = createSelector(selectOnBoarding, (onboarding) => onboarding.scoreRateEndurance);
const selectScoreRateConsistency = createSelector(selectOnBoarding, (onboarding) => onboarding.scoreRateConsistency);
const selectScoreRateStrength = createSelector(selectOnBoarding, (onboarding) => onboarding.scoreRateStrength);

export {
    selectOnBoarding,
    selectGoal,
    selectGoalWide,
    selectAge,
    selectCurrentBody,
    selectCurrentBodyTypeA,
    selectCurrentWeight,
    selectTall,
    selectTargetWeight,
    selectTargetZone,
    selectFitnessLevel,
    selectMeasureSystem,
    selectTrainingLocations,
    selectConsentMarketingStatus,
    selectNextStep,
    selectPreferActivity,
    selectConfig,
    selectNumberSystem,
    selectTargetBody,
    selectURLParams,
    selectSedentaryLifeStyle,
    selectActivityLvl,
    selectOccasion,
    selectAgeGroup,
    selectSport,
    selectIsSkipOB,
    selectEquipmentPreset,
    selectTrainingDaysGoal,
    selectTrainingDuration,
    selectScoreRateEndurance,
    selectScoreRateStrength,
    selectScoreRateConsistency,
};
